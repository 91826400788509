module controllers {
    export module master {
        interface ISkuRefundClaimAmountsScope extends ng.IScope {
        }
        interface ISkuRefundClaimAmountsParams extends ng.ui.IStateParamsService {
        }

        export class skuRefundClaimAmountsController {
            static inject = ['$scope', 
                '$rootScope', 
                'generalService', 
                '$q', 
                '$anchorScroll', 
                'bsLoadingOverlayService', 
                '$state', 
                '$timeout', 
                '$stateParams',
                'skuRefundClaimAmountsService',
                'skuService',
                'countryService',
                'taxTypeService',
                'revenueAllocationCodeService',
                'unitOfMeasureService',
            ];

            items: string[];

            skuRefundClaimAmountsList: Array<interfaces.master.ISkuRefundClaimAmountsDisplay> = [];
            apiSkuRefundClaimAmountsList: uiGrid.IGridApi;
            skuLockDown: boolean;
            startDateOpen: boolean = false;
            endDateOpen: boolean = false;

            entity: interfaces.applicationcore.IDropdownModel;
            entityId: number = undefined;
            IsLoading: boolean = true;
            showAll: boolean = true;

            skuRefundClaimAmountsSearch: interfaces.master.ISkuRefundClaimAmountsSearch = {
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };
            
            sortName: string;
            sortDirection: string;

            constructor(private $scope: ISkuRefundClaimAmountsScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private $stateParams: ISkuRefundClaimAmountsParams,
                private skuRefundClaimAmountsService: interfaces.master.ISkuRefundClaimAmountsService,
                private skuService: interfaces.master.ISKUService,
                private countryService: interfaces.applicationcore.ICountryService,
                private taxTypeService: services.tariff.taxTypeService,
                private revenueAllocationCodeService: services.tariff.revenueAllocationCodeService,
                private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
            ) {
                this.skuRefundClaimAmountsSearch = {
                    ShowAllActive: true,
                };
                this.loadSkuRefundClaimAmountss();
                this.loadEditRight();
            }

            loadSkuRefundClaimAmountss() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'master.skuRefundClaimAmounts'
                }, () => {
                    var searchObject: interfaces.applicationcore.ISearchObject = {
                        filters: [],
                        sorts: []
                    }

                    angular.forEach(this.skuRefundClaimAmountsSearch, (n, key) => {
                        if (n["Id"]) {
                            searchObject.filters.push({ Name: key, Value: n.Id });
                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    });

                    if (this.apiSkuRefundClaimAmountsList) {
                        var grid = this.apiSkuRefundClaimAmountsList.grid;
                        angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                            if (n.filters[0].term) {
                                searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                            }

                            if (n.sort.direction) {
                                searchObject.sorts.push({
                                    Name: n.name,
                                    SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                    SortOrder: n.sort.priority
                                });
                            }
                        });
                    }

                    var params = {
                        connectSearch: searchObject,
                        numberRecords: this.paginationOptions.pageSize,
                        pageNumber: this.paginationOptions.pageNumber,
                    }
                    //Loads SkuRefundClaimAmounts
                    return this.skuRefundClaimAmountsService.getList().query(params, (result: Array<interfaces.master.ISkuRefundClaimAmountsDisplay>) => {
                        this.skuRefundClaimAmountsList = result;

                        this.$timeout(() => {
                            this.gvwSkuRefundClaimAmountsList.data = result;

                            if (result[0]) {
                                this.gvwSkuRefundClaimAmountsList.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwSkuRefundClaimAmountsList.totalItems = 0;
                            }
                        });
                        this.IsLoading = false;
                    }, (errorResponse) => {
                          this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                          this.IsLoading = false;
                    }).$promise;
                });
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.skuRefundClaimAmountsSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiSkuRefundClaimAmountsList) {

                    var grid = this.apiSkuRefundClaimAmountsList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    items: this.items,
                    entityCode: this.entity,
                }

                this.skuRefundClaimAmountsService.getSkuRefundClaimAmountsExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }


            loadEditRight() {
                this.IsLoading = true;
                return this.skuRefundClaimAmountsService.hasEditRight().get((result: interfaces.master.ISKURight) => {
                    this.skuLockDown = result.HasRight;

                    this.IsLoading = false;
                }, (errorResponse) => {
                      this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                      this.IsLoading = false;
                });
            }

            loadSKUs(searchText: string) {
                return this.skuService.getDropdownList(0, searchText).query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            };

            loadCountries(searchText: string) {
                return this.countryService.getDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            };

            loadTaxType(countryId: number) {
                return this.taxTypeService.getDropdownList(countryId).query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            };

            taxTypeChanged(model: interfaces.applicationcore.IDropdownModel, row: uiGrid.IGridRow) {
                if(!model) {
                    row.entity.Country = null;
                    row.entity.TaxType = model;
                }
            }

            loadAllocations(searchText: string, row: uiGrid.IGridRow) {
                return this.revenueAllocationCodeService.getDropdownList(row.entity.Country.Id, row.entity.TaxType.Id, null, searchText, 25).query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            };

            allocationsChanged(model: interfaces.applicationcore.IDropdownModel, row: uiGrid.IGridRow) {
                if(!model) {
                    row.entity.Country = null;
                    row.entity.TaxType = null;
                    row.entity.Allocation = model;
                }
            }

            loadUoMs() {
                return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.$rootScope.Entity.Id }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            };

            addNewSkuRefundClaimAmounts() {
                var add = <interfaces.master.ISkuRefundClaimAmountsDisplay>{
                    Amount: 0,
                    IsActive: true,
                };
                (<Array<any>>(this.gvwSkuRefundClaimAmountsList.data)).splice(0, 0, add);
            };

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.loadSkuRefundClaimAmountss();
            }

            ListShortChange(gridApi) {
                this.loadSkuRefundClaimAmountss();
            }



            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiSkuRefundClaimAmountsList = gridApi;

                this.apiSkuRefundClaimAmountsList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiSkuRefundClaimAmountsList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });

                this.apiSkuRefundClaimAmountsList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiSkuRefundClaimAmountsList.selection.selectRow(gridSelection.row.entity);
                });

                this.$timeout(() => {
                    this.gvwSkuRefundClaimAmountsList.data = this.skuRefundClaimAmountsList;
                });
            };

            gvwSkuRefundClaimAmountsList: uiGrid.IGridOptions | any = {
                data: <Array<interfaces.master.ISkuRefundClaimAmountsDisplay>>[],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                enableCellEditOnFocus: true,
                cellEditableCondition: true,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 100],
                paginationPageSize: 25,
                showGridFooter: false,
                //Grid Menu
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterExcelFilename: 'SkuRefundClaimAmountsList.xlsx',
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['REMOVE', 'INFO'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi); },
                columnDefs: [{
                    name: "REMOVE",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-click="grid.appScope.skuRefundClaimAmountsController.removeInlineSkuRefundClaimAmounts(row.entity)" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown" class="btn btn-warning btn-sm">
                            Delete
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    enableCellEditOnFocus: false,
                    enableCellEdit: false,
                    cellEditableCondition: false,
                    width: 55,
                    headerCellTemplate: `
                        <div class="GridButton">
                            <button type="button"  class="btn btn-default btn-sm" ng-click="grid.appScope.skuRefundClaimAmountsController.addNewSkuRefundClaimAmounts()" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                }, {
                    name: "SKU",
                    displayName: "* SKU",
                    field: "SKU",
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    width: 200,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.skuRefundClaimAmountsController.loadSKUs(searchText)" pagination-search="true" refresh-delay="1000" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown"></gts-dropdown>
                            </form>`,
                }, {
                    name: "Country",
                    displayName: "* Country",
                    field: "Country",
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    width: 200,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.skuRefundClaimAmountsController.loadCountries()" pagination-search="true" refresh-delay="1000" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown"></gts-dropdown>
                            </form>`,
                }, {
                    name: "TaxType",
                    displayName: "* Tax Type",
                    field: "TaxType",
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    width: 200,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.skuRefundClaimAmountsController.loadTaxType(row.entity.Country.Id)" pagination-search="true" refresh-delay="1000" ng-disabled="!row.entity.Country.Id || !grid.appScope.skuRefundClaimAmountsController.skuLockDown"  ng-change="grid.appScope.skuRefundClaimAmountsController.taxTypeChanged(model, row)"></gts-dropdown>
                            </form>`,
                }, {
                    name: "Allocation",
                    displayName: "Allocation",
                    field: "Allocation",
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    width: 200,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.skuRefundClaimAmountsController.loadAllocations(searchText, row)" pagination-search="true" refresh-delay="1000" ng-disabled="!row.entity.Country.Id || !row.entity.TaxType.Id || !grid.appScope.skuRefundClaimAmountsController.skuLockDown" ng-change="grid.appScope.skuRefundClaimAmountsController.allocationsChanged(model, row)"></gts-dropdown>
                            </form>`,
                }, {
                    name: "Amount",
                    displayName: "* Amount",
                    field: "Amount",
                    width: 100,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD | number : 2}}">{{MODEL_COL_FIELD | number : 2}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="number" name="Amount-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" step="0.01" min="0.1" ui-grid-editor ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown"/>
                            </p>
                        </form>`,
                }, {
                    name: "UoM",
                    displayName: "UoM",
                    field: "UoM",
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    width: 200,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.skuRefundClaimAmountsController.loadUoMs()" pagination-search="true" refresh-delay="1000" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown"></gts-dropdown>
                            </form>`,
                }, {
                    name: 'StartDate',
                    displayName: '* Start Date',
                    field: 'StartDate',
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    editableCellTemplate: `
                            <form name="inputForm">
                                    <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown"></gts-date>
                            </form>`,
                    width: 100,
                }, {
                    name: 'EndDate',
                    displayName: '* End Date',
                    field: 'EndDate',
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    editableCellTemplate: `
                            <form name="inputForm">
                                    <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown"></gts-date>
                            </form>`,
                    width: 100,
                }, {
                    name: "ACTIVE",
                    displayName: "* Active",
                    field: "IsActive",
                    width: 70,
                    cellTemplate: `<center><input type="checkbox" ng-change="grid.appScope.skuRefundClaimAmountsController.makeRowDirty(row)" ng-model="row.entity.IsActive" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown"></center>`,
                    editableCellTemplate: ` 
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="row.entity.IsActive" ng-disabled="!grid.appScope.skuRefundClaimAmountsController.skuLockDown">
                                </p>
                            </form>`,
                }, {
                    name: "INFO",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                        <div class="GridButton"> 
                            <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                            </gts-stamp-info>
                        </div>`,
                    width: 38,
                    enableSorting: false,
                    enableCellEdit: false,
                    enableColumnResizing: false,
                    enableCellEditOnFocus: false,
                    cellEditableCondition: false,
                },]
            };

            makeRowDirty(row: uiGrid.IGridRow) {
                this.apiSkuRefundClaimAmountsList.rowEdit.setRowsDirty([row.entity]);
            }

            entityChange(model: interfaces.applicationcore.IDropdownModel) {
                this.entity = model;

                if (model)
                    this.entityId = model.Id;
                else
                    this.entityId = undefined;
            }

            removeInlineSkuRefundClaimAmounts(entity: interfaces.master.ISkuRefundClaimAmountsDisplay) {
                if (entity.Id > 0)
                {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'master.skuRefundClaimAmounts'
                    }, () => {
                        return this.skuRefundClaimAmountsService.removeSkuRefundClaimAmounts().delete({
                            srcaId: entity.Id,
                        }, (result) => {
                            this.$timeout(() => {
                                this.loadSkuRefundClaimAmountss();
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
                else
                {
                    _.remove(this.gvwSkuRefundClaimAmountsList.data, (o) => {
                        return o === entity;
                    });
                }
            }

            //Submit
            submitSkuRefundClaimAmounts() {
                //Get dirty SkuRefundClaimAmountss.
                var skuRefundClaimAmountsList: Array<interfaces.master.ISkuRefundClaimAmountsDisplay> = [];
                if (this.apiSkuRefundClaimAmountsList && this.apiSkuRefundClaimAmountsList.rowEdit.getDirtyRows().length > 0) {
                    skuRefundClaimAmountsList = _.map(this.apiSkuRefundClaimAmountsList.rowEdit.getDirtyRows(), (o) => {
                        return o.entity;
                    });
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'master.skuRefundClaimAmounts'
                }, () => {
                    //Save Dirty SkuRefundClaimAmountss
                    return this.skuRefundClaimAmountsService.saveSkuRefundClaimAmountss().save(skuRefundClaimAmountsList, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);

                        this.$timeout(() => {
                            if (this.apiSkuRefundClaimAmountsList) {
                                this.apiSkuRefundClaimAmountsList.rowEdit.setRowsClean(skuRefundClaimAmountsList);
                                this.loadSkuRefundClaimAmountss();
                            }
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            };

            Close() {
                this.entity = undefined;
                this.entityId = undefined;
                this.$anchorScroll("topAnchor");
                this.$state.go("^");
            }
        }

        angular.module("app").controller("skuRefundClaimAmountsController", controllers.master.skuRefundClaimAmountsController);
    }
}
